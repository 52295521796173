import { useEffect } from "react";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuListProps,
  Text,
  TextProps,
  useColorModeValue,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useAppSelector } from "store/hooks";
import fetchMlParameters from "views/admin/calculations/calculationDetails/api/fetchMlParameters";
import { PredictionResultsMlParams } from "views/admin/calculations/calculationResults/api/fetchPredictionResultsMlParams";
import { useIntl } from "react-intl";
import fetchPredictionResultsMlParams from "views/admin/calculations/calculationResults/api/fetchPredictionResultsMlParams";
import fetchUserBasedFields from "../api/useFetchUserBasedFields";

const menuListAttributes: MenuListProps = {
  position: "absolute",
  top: "0",
  left: "20",
  zIndex: "1",
  color: "black",
  w: "fit-content",
  height: "fit-content",
  maxHeight: "container.sm",
  overflowY: "auto",
  borderRadius: "md",
  boxShadow: "md",
};

const menuListItemAttributes: TextProps = {
  p: "5",
  bg: "white",
  _hover: { bg: "blue.400", color: "white" },
  borderBottom: "1px solid",
  whiteSpace: "nowrap",
  borderColor: "gray.500",
};

type SystemVariableSelectorProps = {
  id?: number;
  setValue: (val: string) => void;
  icon?: string;
};
function SystemVariableSelector({
  icon,
  setValue,
}: SystemVariableSelectorProps) {
  const textColor = useColorModeValue("gray.600", "white");
  const userClientId = useAppSelector((state) => state.auth.User.client);
  const intl = useIntl();
  const currentLocale = intl.locale;
  const [cadMouseEnter, setCadMouseEnter] = useState(false);
  const [systemMouseEnter, setSystemMouseEnter] = useState(false);
  const [userMouseEnter, setUserMouseEnter] = useState(false);
  const [predictionVariableMouseEnter, setPredictionVariableMouseEnter] =
    useState(false);
  const { data: predictionResults, refetch: refetchPredictionMLParameters } =
    useQuery<PredictionResultsMlParams[]>({
      queryKey: ["predictionresultmlparameters", userClientId],
      queryFn: () => fetchPredictionResultsMlParams(userClientId),
    });

  const { data: cadmlParameters, refetch: refetchMlParameters } = useQuery({
    queryKey: ["MlParameters"],
    queryFn: fetchMlParameters,
  });

  const { data: userBasedFields, refetch: refetchUserBasedFields } = useQuery({
    queryKey: ["UserBasedFields"],
    queryFn: fetchUserBasedFields,
  });

  const handleCadMouseEnter = () => {
    setCadMouseEnter(true);
  };
  const handleCadMouseLeave = () => {
    setCadMouseEnter(false);
  };
  const handleSystemMouseEnter = () => {
    setSystemMouseEnter(true);
  };
  const handleSystemMouseLeave = () => {
    setSystemMouseEnter(false);
  };
  const handleUserMouseEnter = () => {
    setUserMouseEnter(true);
  };
  const handleUserMouseLeave = () => {
    setUserMouseEnter(false);
  };
  const handlePredictionVariableMouseEnter = () => {
    setPredictionVariableMouseEnter(true);
  };
  const handlePredictionVariableMouseLeave = () => {
    setPredictionVariableMouseEnter(false);
  };
  const sortedCadmlParameters = cadmlParameters?.sort((a, b) =>
    a.display_name.localeCompare(b.display_name),
  );
  const sortedPredictionResults = predictionResults?.sort((a, b) =>
    a.display_name.localeCompare(b.display_name),
  );
  const sortedUserBasedFields = userBasedFields?.sort(
    (a: { display_name: string }, b: { display_name: any }) =>
      a.display_name.localeCompare(b.display_name),
  );

  useEffect(() => {
    refetchUserBasedFields();
    refetchMlParameters();
    refetchPredictionMLParameters();
  }, [currentLocale]);

  return (
    <Menu>
      <MenuButton
        fontSize="xs"
        as={IconButton}
        w="fit-content"
        aria-label="Options"
        variant="outline"
      >
        {icon === "edit" ? <EditIcon /> : <ChevronDownIcon />}
      </MenuButton>
      <Button
        fontSize="xs"
        w="fit-content"
        aria-label="Options"
        variant="outline"
        onClick={() => setValue("")}
      >
        <DeleteIcon />
      </Button>

      <MenuList>
        <MenuItem
          onMouseEnter={handleCadMouseEnter}
          onMouseLeave={handleCadMouseLeave}
        >
          <Text color={textColor}>
            <FormattedMessage
              id="user.systemVariables.cad"
              defaultMessage="Cad variables"
            />
          </Text>
          <ChevronRightIcon />
          {cadMouseEnter && (
            <Box {...menuListAttributes}>
              {sortedCadmlParameters?.map((param) => (
                <Text
                  key={param.id}
                  {...menuListItemAttributes}
                  onClick={(e) => {
                    setValue(e.currentTarget.textContent);
                  }}
                >
                  {param.display_name}
                </Text>
              ))}
            </Box>
          )}
        </MenuItem>
        <MenuItem
          position="relative"
          onMouseEnter={handleSystemMouseEnter}
          onMouseLeave={handleSystemMouseLeave}
        >
          <Text color={textColor}>
            <FormattedMessage
              id="user.systemVariables.system"
              defaultMessage="System variables"
            />
          </Text>
          <ChevronRightIcon />
          {systemMouseEnter && (
            <Box {...menuListAttributes}>
              <Text
                {...menuListItemAttributes}
                onClick={(e) => {
                  setValue(e.currentTarget.textContent);
                }}
              >
                <FormattedMessage
                  id="user.systemVariables.offerN"
                  defaultMessage="Offer Number"
                />
              </Text>
            </Box>
          )}
        </MenuItem>
        <MenuItem
          position="relative"
          onMouseEnter={handlePredictionVariableMouseEnter}
          onMouseLeave={handlePredictionVariableMouseLeave}
        >
          <Text color={textColor}>
            <FormattedMessage
              id="user.systemVariables.prediction"
              defaultMessage="Prediction variables"
            />
          </Text>
          <ChevronRightIcon />
          {predictionVariableMouseEnter && (
            <Box {...menuListAttributes}>
              {sortedPredictionResults?.map((param) => (
                <Text
                  key={param.id}
                  {...menuListItemAttributes}
                  onClick={(e) => {
                    setValue(e.currentTarget.textContent);
                  }}
                >
                  {param.display_name}
                </Text>
              ))}
            </Box>
          )}
        </MenuItem>

        <MenuItem
          position="relative"
          onMouseEnter={handleUserMouseEnter}
          onMouseLeave={handleUserMouseLeave}
        >
          <Text color={textColor}>
            <FormattedMessage
              id="user.systemVariables.userVariables"
              defaultMessage="User variables"
            />
          </Text>
          <ChevronRightIcon />
          {userMouseEnter && (
            <Box {...menuListAttributes}>
              {sortedUserBasedFields?.map((param: any) => (
                <Text
                  key={param.id}
                  {...menuListItemAttributes}
                  onClick={(e) => {
                    setValue(e.currentTarget.textContent);
                  }}
                >
                  {param.display_name}
                </Text>
              ))}
            </Box>
          )}
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default SystemVariableSelector;
