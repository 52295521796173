import Language from "./types";

const en: Language = {
  // Global
  "global.toasts.welcome": "Welcome to Casculate",
  "global.toasts.calculationCreated":
    "CAD parameters are being extracted. Calculation status will be updated shortly.",
  "global.toasts.errorFetchingCalculations":
    "Something went wrong while fetching calculations.",
  "global.toasts.errorDeletingCalculation":
    "Something went wrong while deleting calculation.",
  "global.toasts.customerEdited": "Customer edited successfully.",
  "global.toasts.errorAddingCustomer":
    "Something went wrong while adding customer.",
  "global.toasts.editedPartname": "Partname edited successfully.",
  "global.toasts.errorEditingPartname":
    "Something went wrong editing partname.",
  "global.toasts.deleted": "Calculation deleted successfully.",
  "global.toasts.changed": "Successfully changed.",
  "global.toasts.noChanges": "No changes in form.",
  "global.toasts.errorFetchingInputP":
    "Something went wrong while fetching input parameters.",
  "global.toasts.errorLogin": "Wrong Credentials!",
  "global.toasts.calcUpdated": "Calculation updated.",
  "global.toasts.errorUpdatingCalc": "Error while updating.",
  "global.toasts.partingPlaneUpdated":
    "Partition plane information updated successfully.",
  "global.toasts.errorUpdatingPartingPlane":
    "Failed to update partition plane information.",
  "global.toasts.errorCalculatingCores": "Error calculating cores",
  "global.toasts.errorCopyingCalculation": "Error copying calculation",
  "global.warning.missingEntries":
    "You have unfilled enteries. Are you sure you want to leave?",
  "global.warning.uncompleted":
    "You have uncompleted fields and your process will be lost. Are you sure you want to leave?",
  // Settings
  "global.toasts.addEmail": "Please add an email to your account first",
  "global.toasts.twoFactorEnabled": "Two Factor Authentication Enabled",
  "global.toasts.twoFactorEnable": "Enable Two Factor Authentication",
  "global.toasts.twoFAEnabled": "2FA enabled",
  "global.toasts.invalidOTP": "Invalid OTP or OTP expired.",
  "global.settings.twoFactor": "Two Factor Authentication",
  "global.settings.setup2FA": "Setup 2FA",
  "global.settings.twoFAEnableB": "Enable",
  "global.settings.twoFAEnabledB": "EnableD",
  "global.settings.scanQRCode":
    "Scan the above code with your favorite authenticator app to setup 2FA. Enter the generated code below to verify.",
  "global.settings.verify": "Verify",
  "global.settings.oldPassword": "Old Password:",
  "global.settings.newPassword": "New Password:",
  "global.settings.passwordRequirements":
    "Your password should be more than 8 letters containing at least one digit and one alphabet.",
  "global.settings.passwordLength":
    "Password must be at least 8 characters long",
  "global.settings.confirmPassword": "Confirm Password:",
  "global.settings.passwordMismatch": "Your passwords do not match.",
  "global.settings.change": "Change",
  // Navbar
  "global.breadcrumb.user": "User",
  "global.breadcrumb.dashboard": "Dashboard",
  "global.breadcrumb.details": "Details",
  "global.breadcrumb.cadfile": "Cad File",
  "global.breadcrumb.partnames": "Partnames",
  "global.breadcrumb.customers": "Customers",
  "global.breadcrumb.excel-baseline": "Excel Baseline",
  "global.breadcrumb.config-excel-baseline": "Config Excel Baseline",
  "global.breadcrumb.results": "Results",
  "global.breadcrumb.newCalculation": "New Calculation",
  "global.breadcrumb.404": "Not Found",
  "global.navbar.settings": "Settings",
  "global.navbar.logout": "Logout",
  "global.navbar.twoFA": "We recommend enabling 2FA for your account.",
  "global.navbar.pages": "Home",
  //Footer
  "global.footer.allRightsReserved": "Casculate. All Rights Reserved.",
  "global.footer.privacyPolicy": "Privacy Policy",
  "global.footer.support": "Support",
  // Sidebar
  "sidebar.user.Dashboard": "Dashboard",
  "sidebar.user.Calculation": "Calculation",
  "sidebar.user.Calculation_Inputs": "Calculation Inputs",
  "sidebar.user.Details": "Details",
  "sidebar.user.Result": "Result",
  "sidebar.user.Settings": "Settings",
  "sidebar.user.Partnames": "Partnames",
  "sidebar.user.Customers": "Customers",
  "sidebar.user.Excel_Baseline": "Excel Baseline",
  "sidebar.user.Config_Excel_Baseline": "Config Excel Baseline",
  "sidebar.user.Not_Found": "Not Found",
  // Login Data
  "login.signIn": "Sign In",
  "login.signInDescription": "Enter your username and password to sign in!",
  "login.username": "Username",
  "login.password": "Password",
  "login.signInButton": "Sign In",
  "login.footer.allRightsReserved": "Casculate. All Rights Reserved.",
  "login.footer.support": "Support",
  "login.footer.license": "License",
  "login.footer.termsOfUse": "Terms of Use",
  "login.footer.blog": "Blog",
  // User App
  // Dashboard
  "user.dashboard.detailButton": "Detail",
  "user.dashboard.resultsButton": "Results",
  "user.dashboard.ascending": "Ascending",
  "user.dashboard.descending": "Descending",
  "user.dashboard.statusAll": "All",
  "user.dashboard.statusCreated": "Created",
  "user.dashboard.statusPreprocessing": "Preprocessing",
  "user.dashboard.statusDraft": "Draft",
  "user.dashboard.statusError": "Error",
  "user.dashboard.statusAborted": "Aborted",
  "user.dashboard.statusFinished": "Finished",
  "user.dashboard.modelWarning":
    "This calculation was run with an outdated AI model. You can rerun this calculation with the latest models.",

  "user.dashboard.calculationId": "Calculation Id",
  "user.dashboard.CRMNumber": "CRM Number",
  "user.dashboard.status": "Status",
  "user.dashboard.createdBy": "Created By",
  "user.dashboard.customer": "Customer",
  "user.dashboard.creationDate": "Creation Date",
  "user.dashboard.actions": "Actions",
  "user.dashboard.details": "Details",
  "user.dashboard.results": "Results",
  "user.dashboard.noCalculationsFound": "No calculations found.",
  // Status
  "user.dashboard.status.finished": "Finished",
  "user.dashboard.status.draft": "Draft",
  "user.dashboard.status.error": "Error",
  "user.dashboard.status.created": "Created",
  "user.dashboard.status.erstellt": "Created",
  "user.dashboard.status.preprocessing": "Preprocessing",
  "user.dashboard.status.aborted": "Aborted",
  // Filters
  "user.dashboard.statusFilter": "Status",
  "user.dashboard.dateFilter": "Date",
  // Delete modal
  "dashboard.deleteModal.title": "Delete Calculation",
  "dashboard.deleteModal.text":
    "Are you sure you want to delete this calculation?",
  "dashboard.deleteModal.cancel": "Cancel",
  "dashboard.deleteModal.delete": "Delete",

  // New calculation
  "calculation.heading": "Set Calculation Parameters",
  "calculation.processstateRaw": "RAW",
  "calculation.processstateFinished": "FINISHED",
  // Modal already existing file
  "calculation.newCalcModal.title": "Choose an option",
  "calculation.newCalcModal.desc":
    "Some calculations already exist against this file.",
  "calculation.newCalcModal.question":
    "Do you want to open it or create new one?",
  "calculation.newCalcModal.open": "Open",
  "calculation.newCalcModal.create": "Create New",
  // Dropzone
  "calculation.dropzone.title": "Upload Files",
  "calculation.dropzone.desc": "STEP, and STL files are allowed.",
  "calculation.dropzone.button": "Next",
  // Partnames and customers
  "calculation.data.search": "Search",
  "calculation.data.add": "Add",
  "calculation.data.customerName": "Customer Name:",
  "calculation.data.addPartname": "Add Partname",
  "calculation.data.editPartname": "Edit Partname",
  "calculation.data.edit": "Edit",
  "calculation.data.displayNameEN": " Display Name (EN)",
  "calculation.data.displayNameDE": " Display Name (DE)",
  "calculation.data.descriptionEN": "Description (EN)",
  "calculation.data.descriptionDE": "Description (DE)",
  "calculation.data.addCustomer": "Add Customer",
  "calculation.data.editCustomer": "Edit Customer",
  "calculation.data.save": "Save",
  // Calculation details
  "calculation.createdBy": "Created by: ",
  "calculation.detail.customerName": "Customer Name: ",
  "calculation.detail.customerPartNum": "Customer Part Number: ",
  "calculation.detail.customerPartName": "Customer Part Name: ",
  "calculation.detail.material": "Material Name: ",
  "calculation.process": "Process State: ",
  "calculation.weight": "Weight: ",
  "calculation.cavities": "Number of cavities: ",
  "calculation.volume": "Total volume of cavities: ",
  "calculation.module": "Module: ",
  "calculation.calculation": "Calculation: ",
  "calculation.status": "Status: ",
  "calculation.detail.finished": "Finished",
  "calculation.detail.showResults": "Show results",
  "calculation.detail.runCalc": "Run calculation",
  "calculation.detail.runCoreDetec": "Run core detection",
  "calculation.detail.coresWarning":
    "This information is outdated or not defined, please run the core detection.",
  "calculation.detail.copyCalc": "Copy calculation",
  "calculation.detail.parent": "Created from ID: ",
  "calculation.detail.axesToggle": "Show Axes",
  // Input parameters
  "calculation.detail.inputParameters": "Input Parameters",
  // Cad parameters
  "calculation.detail.cadParameters": "CAD Parameters",
  "calculation.detail.parameterName": "Parameter Name",
  "calculation.detail.parameterValue": "Parameter Value",
  "calculation.detail.unit": "Unit",
  "calculation.detail.extractedP": "Extracted Parameter",
  "calculation.detail.download": "Download",
  "calculation.detail.parameter": "Parameter",
  "calculation.detail.value": "Value",
  // Result loader
  "calculation.detail.resultLoader":
    "Your results might take 2 minutes to generate.",
  // Excel Baseline
  "user.excelBaseline.add": "Add New",
  "user.excelBaseline.notFound": "No baselines found",
  "user.excelBaseline.placeholdersFound": "Placeholders found",
  "user.excelBaseline.systemVariable": "System Variable",
  "user.excelBaseline.sheetName": "Sheet Name",
  "user.excelBaseline.cellPosition": "Cell Position",
  "user.excelBaseline.configureExcel": "Configure Excel",
  "user.excelBaseline.mandatoryFieldsNotice":
    "* All fields are mandatory for functionality to work.",
  "user.excelBaseline.status": "Status",
  "user.excelBaseline.completed": "Completed",
  "user.excelBaseline.incomplete": "Incomplete",
  "user.excelBaseline.client": "Client",
  "user.excelBaseline.noBaselineDataFound": "No Baseline Data found",
  "user.excelBaseline.finalCost": "Final Cost",
  "user.excelBaseline.dataType": "Type of data",
  "user.excelBaseline.decimal": "Decimal",
  "user.excelBaseline.set": "Set",
  // Add Baseline Modal
  "user.excelBaseline.excelFile": "Excel File",
  "user.excelBaseline.leftMarker": "Left Marker",
  "user.excelBaseline.rightMarker": "Right Marker",
  "user.excelBaseline.defaultMarkers":
    "*Markers are filled by default. Change if necessary.",
  "user.excelBaseline.clientBaseline": "You are setting for: ",
  "user.excelBaseline.loading": "Loading",
  "user.excelBaseline.uploading": "Uploading...",
  "user.excelBaseline.upload": "Upload",
  "user.systemVariables.cad": "Cad variables",
  "user.systemVariables.system": "System variables",
  "user.systemVariables.offerN": "Offer Number",
  "user.systemVariables.prediction": "Prediction variables",
  "user.systemVariables.userVariables": "User variables",

  // Not Found View
  "user.excelBaseline.idNotFound": "Excel Baseline not Found",
  "user.results.resultsNotFound": "Results not Found",
  "user.newCalc.error":
    "There was an error creating the calculation, please start again",
  "user.calcDetail.idNotFound": "Calculation detail not found",
  "user.cadFile.redirect": "Return to upload file",
  "user.dashboard.redirect": "Return to dashboard",
  "user.excelBaseline.redirect": "Return to baseline's list",
};

export default en;
