// de.js
import Language from "./types";

const de: Language = {
  // Global
  "global.toasts.welcome": "Willkommen bei Casculate",
  "global.toasts.calculationCreated":
    "CAD-Parameter werden extrahiert. Der Status wird in Kürze aktualisiert.",
  "global.toasts.errorFetchingCalculations":
    "Beim Abrufen der Kalkulationen ist ein Fehler aufgetreten.",
  "global.toasts.errorDeletingCalculation":
    "Beim Löschen der Kalkulation ist ein Fehler aufgetreten.",
  "global.toasts.customerEdited": "Kunde erfolgreich bearbeitet.",
  "global.toasts.errorAddingCustomer":
    "Beim Hinzufügen des Kunden ist ein Fehler aufgetreten.",
  "global.toasts.editedPartname": "Teil-Bezeichnung erfolgreich bearbeitet.",
  "global.toasts.errorEditingPartname":
    "Beim Bearbeiten der Teil-Bezeichnung ist ein Fehler aufgetreten.",
  "global.toasts.deleted": "Die Kalkulation wurde erfolgreich gelöscht.",
  "global.toasts.changed": "Erfolgreich geändert.",
  "global.toasts.noChanges": "Keine Änderungen im Formular.",
  "global.toasts.errorFetchingInputP":
    "Beim Abrufen der Eingabeparameter ist ein Fehler aufgetreten.",
  "global.toasts.errorLogin": "Falsche Anmeldeinformationen!",
  "global.toasts.calcUpdated": "Kalkulation aktualisiert.",
  "global.toasts.errorUpdatingCalc": "Fehler beim Aktualisieren.",
  "global.toasts.partingPlaneUpdated":
    "Die Teilungsebene wurde erfolgreich angepasst.",
  "global.toasts.errorUpdatingPartingPlane":
    "Aktualisierung der Teilungsebene.",
  "global.toasts.errorCalculatingCores": "Fehler beim Berechnen der Kerne",
  "global.toasts.errorCopyingCalculation":
    "Fehler beim Kopieren der Kalkulation",
  "global.warning.missingEntries":
    "Sie haben nicht ausgefüllte Einträge. Sind Sie sicher, dass Sie die Seite verlassen möchten?",
  "global.warning.uncompleted":
    "Sie haben unvollständige Felder und Ihr Prozess geht verloren. Sind Sie sicher, dass Sie die Seite verlassen möchten?",
  // Settings
  "global.toasts.addEmail":
    "Bitte fügen Sie zuerst eine E-Mail zu Ihrem Konto hinzu.",
  "global.toasts.twoFactorEnabled": "Zwei-Faktor-Authentifizierung aktiviert",
  "global.toasts.twoFactorEnable": "Zwei-Faktor-Authentifizierung aktivieren",
  "global.toasts.twoFAEnabled": "2FA aktiviert",
  "global.toasts.invalidOTP": "Ungültige OTP oder OTP abgelaufen.",
  "global.settings.twoFactor": "Zwei-Faktor-Authentifizierung",
  "global.settings.setup2FA": "Einrichtung von 2FA",
  "global.settings.twoFAEnableB": "Aktivieren",
  "global.settings.twoFAEnabledB": "Aktiviert",
  "global.settings.scanQRCode":
    "Scannen Sie den obigen Code mit Ihrer bevorzugten Authenticator-App, um 2FA einzurichten. Geben Sie danach den generierten Code unten ein.",
  "global.settings.verify": "Überprüfen",
  "global.settings.oldPassword": "Altes Passwort:",
  "global.settings.newPassword": "Neues Passwort:",
  "global.settings.passwordRequirements":
    "Ihr Passwort sollte mehr als 8 Zeichen lang sein und mindestens eine Ziffer und einen Buchstaben enthalten.",
  "global.settings.passwordLength":
    "Das Passwort muss mindestens 8 Zeichen lang sein",
  "global.settings.confirmPassword": "Passwort bestätigen:",
  "global.settings.passwordMismatch": "Die Passwörter stimmen nicht überein.",
  "global.settings.change": "Ändern",
  // Navbar
  "global.breadcrumb.user": "Benutzer",
  "global.breadcrumb.dashboard": "Dashboard",
  "global.breadcrumb.details": "Details",
  "global.breadcrumb.cadfile": "CAD-Datei",
  "global.breadcrumb.partnames": "Teile-Bezeichnung",
  "global.breadcrumb.customers": "Kunden",
  "global.breadcrumb.excel-baseline": "Excel-Vorlage",
  "global.breadcrumb.config-excel-baseline": "Excel-Vorlage konfigurieren",
  "global.breadcrumb.results": "Ergebnisse",
  "global.breadcrumb.newCalculation": "Neue Kalkulation",
  "global.breadcrumb.404": "Nicht gefunden",
  "global.navbar.settings": "Einstellungen",
  "global.navbar.logout": "Abmelden",
  "global.navbar.twoFA":
    "Wir empfehlen, die Zwei-Faktor-Authentifizierung (2FA) für Ihr Konto zu aktivieren.",
  "global.navbar.pages": "Startseite",
  //Footer
  "global.footer.allRightsReserved": "Casculate. Alle Rechte vorbehalten.",
  "global.footer.privacyPolicy": "Datenschutzrichtlinie",
  "global.footer.support": "Unterstützung",
  // Sidebar
  "sidebar.user.Dashboard": "Dashboard",
  "sidebar.user.Calculation": "Kalkulation",
  "sidebar.user.Calculation_Inputs": "Kalkulationseingaben",
  "sidebar.user.Details": "Details",
  "sidebar.user.Result": "Ergebnis",
  "sidebar.user.Settings": "Einstellungen",
  "sidebar.user.Partnames": "Teile-Bezeichnung",
  "sidebar.user.Customers": "Kunden",
  "sidebar.user.Excel_Baseline": "Excel-Vorlage",
  "sidebar.user.Config_Excel_Baseline": "Konfigurations-Excel-Baseline",
  "sidebar.user.Not_Found": "Nicht gefunden",
  // Login Data
  "login.signIn": "Anmelden",
  "login.signInDescription":
    "Geben Sie Ihren Benutzernamen und Ihr Passwort ein, um sich anzumelden!",
  "login.username": "Benutzername",
  "login.password": "Passwort",
  "login.signInButton": "Anmelden",
  "login.footer.allRightsReserved": "Casculate. Alle Rechte vorbehalten.",
  "login.footer.support": "Support",
  "login.footer.license": "Lizenz",
  "login.footer.termsOfUse": "Nutzungsbedingungen",
  "login.footer.blog": "Blog",

  // User App
  // Dashboard
  "user.dashboard.detailButton": "Detail",
  "user.dashboard.resultsButton": "Ergebnisse",
  "user.dashboard.ascending": "Aufsteigend",
  "user.dashboard.descending": "Absteigend",
  "user.dashboard.statusAll": "Alle",
  "user.dashboard.statusCreated": "Erstellt",
  "user.dashboard.statusPreprocessing": "Vorverarbeitung",
  "user.dashboard.statusDraft": "Entwurf",
  "user.dashboard.statusError": "Fehler",
  "user.dashboard.statusAborted": "Abgebrochen",
  "user.dashboard.statusFinished": "Abgeschlossen",
  "user.dashboard.modelWarning":
    "Diese Kalkulation wurde mit einem obsoleten KI-Modell berechnet. Bitte wiederholen Sie die Kalkulation, um die aktuellsten Modelle zu verwenden.",

  "user.dashboard.calculationId": "Kalkulations-ID",
  "user.dashboard.CRMNumber": "CRM-Nummer",
  "user.dashboard.status": "Status",
  "user.dashboard.createdBy": "Erstellt von",
  "user.dashboard.customer": "Kunde",
  "user.dashboard.creationDate": "Erstellt am",
  "user.dashboard.actions": "Aktionen",
  "user.dashboard.details": "Details",
  "user.dashboard.results": "Ergebnisse",
  "user.dashboard.noCalculationsFound": "Keine Kalkulationen gefunden.",
  // Status
  "user.dashboard.status.finished": "Fertiggestellt",
  "user.dashboard.status.draft": "Entwurf",
  "user.dashboard.status.error": "Fehler",
  "user.dashboard.status.created": "Erstellt",
  "user.dashboard.status.Erstellt": "Erstellt",
  "user.dashboard.status.preprocessing": "Vorverarbeitung",
  "user.dashboard.status.aborted": "Abgebrochen",
  // Filters
  "user.dashboard.statusFilter": "Status",
  "user.dashboard.dateFilter": "Datum",
  // Delete modal
  "dashboard.deleteModal.title": "Lösche Kalkulation",
  "dashboard.deleteModal.text":
    "Sind Sie sicher, dass Sie diese Kalkulationen löschen möchten?",
  "dashboard.deleteModal.cancel": "Abbrechen",
  "dashboard.deleteModal.delete": "Löschen",

  // New calculation
  "calculation.heading": "Berechnungsparameter einstellen",
  "calculation.processstateRaw": "Rohteil",
  "calculation.processstateFinished": "Fertigteil",
  // Modal already existing file
  "calculation.newCalcModal.title": "Wählen Sie eine Option aus",
  "calculation.newCalcModal.desc":
    "Es existieren bereits Kalkulationen zu dieser Datei.",
  "calculation.newCalcModal.question":
    "Möchten Sie die bestehende Kalkulation öffnen oder eine neue erstellen?",
  "calculation.newCalcModal.open": "Öffnen",
  "calculation.newCalcModal.create": "Neu erstellen",
  // Dropzone
  "calculation.dropzone.title": "Dateien hochladen",
  "calculation.dropzone.desc": "Mögliche Dateitypen: STEP und STL",
  "calculation.dropzone.button": "Weiter",
  // Partnames and customers
  "calculation.data.search": "Suchen",
  "calculation.data.add": "Hinzufügen",
  "calculation.data.customerName": "Kundenname:",
  "calculation.data.addPartname": "Teile-Bezeichnung hinzufügen",
  "calculation.data.editPartname": "Teile-Bezeichnung bearbeiten",
  "calculation.data.edit": "Bearbeiten",
  "calculation.data.displayNameEN": "Bezeichnung (EN)",
  "calculation.data.displayNameDE": "Bezeichnung (DE)",
  "calculation.data.descriptionEN": "Beschreibung (EN)",
  "calculation.data.descriptionDE": "Beschreibung (DE)",
  "calculation.data.addCustomer": "Kunde hinzufügen",
  "calculation.data.editCustomer": "Kunde bearbeiten",
  "calculation.data.save": "Speichern",
  // Calculation details
  "calculation.createdBy": "Erstellt von: ",
  "calculation.detail.customerName": "Kundenname: ",
  "calculation.detail.customerPartNum": "Kunden Teilenummer: ",
  "calculation.detail.customerPartName": "Bezeichnung: ",
  "calculation.detail.material": "Material: ",
  "calculation.process": "Prozesszustand: ",
  "calculation.weight": "Gewicht: ",
  "calculation.cavities": "Anzahl der Kavitäten: ",
  "calculation.volume": "Gesamtvolumen der Kavitäten: ",
  "calculation.module": "Modul: ",
  "calculation.calculation": "Kalkulation: ",
  "calculation.status": "Status: ",
  "calculation.detail.draft": "Entwurf",
  "calculation.detail.finished": "Fertiggestellt",
  "calculation.detail.showResults": "Ergebnisse anzeigen",
  "calculation.detail.runCalc": "Berechnen",
  "calculation.detail.runCoreDetec": "Kavitäten berechnen",
  "calculation.detail.coresWarning":
    "Die Kavitäteninformationen sind nicht aktuell. Bitte führen Sie die Kavitätenerkennung aus.",
  "calculation.detail.copyCalc": "Kalkulation kopieren",
  "calculation.detail.parent": "Erstellt aus ID: ",
  "calculation.detail.axesToggle": "Achsen anzeigen",
  // Input parameters
  "calculation.detail.inputParameters": "Eingabeparameter",
  // Cad parameters
  "calculation.detail.cadParameters": "CAD-Parameter",
  "calculation.detail.parameterName": "Parametername",
  "calculation.detail.parameterValue": "Parameterwert",
  "calculation.detail.unit": "Einheit",
  "calculation.detail.extractedP": "Extrahierter Parameter",
  "calculation.detail.download": "Herunterladen",
  "calculation.detail.parameter": "Parameter",
  "calculation.detail.value": "Wert",
  // Result loader
  "calculation.detail.resultLoader":
    "Es kann bis zu 2 Minuten dauern, bis die Ergbnisse verfügbar sind.",
  // Excel Baseline
  "user.excelBaseline.add": "Neu hinzufügen",
  "user.excelBaseline.notFound": "Keine Baselines gefunden",
  "user.excelBaseline.placeholdersFound": "Gefundener Platzhalter",
  "user.excelBaseline.systemVariable": "Systemvariable",
  "user.excelBaseline.sheetName": "Blatt",
  "user.excelBaseline.cellPosition": "Zelle",
  "user.excelBaseline.configureExcel": "Excel konfigurieren",
  "user.excelBaseline.mandatoryFieldsNotice":
    "* Alle Felder sind erforderlich, damit die Funktioniert.",
  "user.excelBaseline.status": "Status",
  "user.excelBaseline.completed": "Abgeschlossen",
  "user.excelBaseline.incomplete": "Unvollständig",
  "user.excelBaseline.client": "Kunde",
  "user.excelBaseline.noBaselineDataFound": "Keine Basiskonfiguration gefunden",
  "user.excelBaseline.finalCost": "Endkosten",
  "user.excelBaseline.dataType": "Datentyp",
  "user.excelBaseline.decimal": "Dezimal",
  "user.excelBaseline.set": "Setzen",
  // Add Baseline Modal
  "user.excelBaseline.excelFile": "Excel-Datei",
  "user.excelBaseline.leftMarker": "Linker Marker",
  "user.excelBaseline.rightMarker": "Rechter Marker",
  "user.excelBaseline.defaultMarkers":
    "*Marker sind standardmäßig gefüllt. Ändern Sie sie bei Bedarf.",
  "user.excelBaseline.clientBaseline": "Sie setzen für ein: ",
  "user.excelBaseline.loading": "Laden",
  "user.excelBaseline.uploading": "Hochladen...",
  "user.excelBaseline.upload": "Hochladen",
  "user.systemVariables.cad": "CAD-Variablen",
  "user.systemVariables.system": "Systemvariablen",
  "user.systemVariables.offerN": "Angebotsnummer",
  "user.systemVariables.prediction": "Vorhersagevariablen",
  "user.systemVariables.userVariables": "Benutzervariablen",

  // Not Found View
  "user.excelBaseline.idNotFound": "Excel-Baseline nicht gefunden",
  "user.results.resultsNotFound": "Ergebnisse nicht gefunden",
  "user.newCalc.error":
    "Beim Erstellen der Berechnung ist ein Fehler aufgetreten. Bitte starten Sie erneut.",
  "user.calcDetail.idNotFound": "Detail der Berechnung nicht gefunden",
  "user.cadFile.redirect": "Zurück zur Datei-Upload",
  "user.dashboard.redirect": "Zurück zum Dashboard",
  "user.excelBaseline.redirect": "Zurück zur Liste der Baselines",
};

export default de;
